<template>
  <div class="page">
    <Navbar title="考核任务" type="SMP" :callback="back" />
    <van-row>
      <van-col span="22" offset="1" class="tips bgcolor-s1">
        <div class="title">自定义分享步骤</div>
        <div class="item">· 填写分享标题和分享描述</div>
        <div class="item">· 点击设置参数按钮设置分享参数</div>
        <div class="item">· 点击右上角"..."进行微信原生分享</div>
        <div class="item">· 点击"生成二维码"生成图片,长按图片进行分享</div>
      </van-col>
    </van-row>
    <van-field
      v-model="share.name"
      name="分享标题"
      label="分享标题"
      placeholder="请输入分享标题"
      :rules="[
        {
          required: true,
          message: '请输入分享标题',
        },
      ]"
    />
    <van-field
      v-model="share.desc"
      name="分享描述"
      label="分享描述"
      rows="2"
      autosize
      type="textarea"
      maxlength="50"
      placeholder="请输入分享描述"
      :rules="[
        {
          required: true,
          message: '请输入分享描述',
        },
      ]"
    />
    <van-field
      v-model="share.comment"
      name="备注"
      label="备注"
      rows="2"
      autosize
      type="textarea"
      maxlength="20"
      show-word-limit
      placeholder="请输入20个字以内的备注信息"
    />
    <van-row class="button">
      <van-col span="24">
        <van-button
          class="btn"
          icon="plus"
          :color="COLOR_M"
          size="small"
          @click="initShare()"
          >设置参数</van-button
        >
        <van-button
          class="btn"
          icon="qr"
          :color="COLOR_S1"
          size="small"
          @click="createQrcode()"
          >生成二维码</van-button
        ></van-col
      >
    </van-row>
    <div class="qrimg" ref="qrimg" v-if="display === 'qrcode'">
      <div class="title">{{ share.name }}</div>
      <div class="img" ref="qrcodeImg"></div>
      <div class="qrtips color-s1">长按图片识别二维码填写信息</div>
    </div>
    <div class="share-img">
      <img ref="shareimg" />
    </div>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Field } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Field.name]: Field
  },
  data () {
    return {
      loadingShow: false,
      display: '',
      share: {
        name: '个人电子资料', desc: '完善您的个人电子资料，便于工作、证书、上学等咨询服务', link: '', comment: ''
      },
      profile: ''
    }
  },
  mounted () {
    var query = this.$route.query
    this.profile = query.profile
    this.initShare('init')
  },
  methods: {
    initShare (type) {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var link = 'https://moc.utopanet.com/member/person?profile=' + this.profile + '&referee=' + user
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      link = link + '&comment=' + this.share.comment
      this.share.link = link
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(this.share.name, this.share.desc, this.share.link, logo)
      if (type !== 'init') {
        this.$dialog.alert({
          title: '提示信息',
          message: '参数设置完成，请点击右上方 \'...\' 或生成二维码进行分享'
        })
      }
    },
    createQrcode () {
      this.display = 'qrcode'
      this.loadingShow = true
      setTimeout(() => {
        var wth = document.body.clientWidth
        this.$refs.qrcodeImg.innerHTML = ''
        var qwidth = (200 / 375) * wth
        var vn = new QRCode(this.$refs.qrcodeImg, {
          text: this.share.link,
          width: qwidth,
          height: qwidth,
          colorDark: '#000000',
          colorLight: '#ffffff'
        })
        console.log(vn)
      }, 2000)
      setTimeout(() => {
        this.saveImage()
      }, 5000)
    },
    saveImage () {
      this.loadingShow = false
      this.display = 'image'
      html2canvas(this.$refs.qrimg, {
        backgroundColor: null, // 画出来的图片有白色的边框,不要可设置背景为透明色（null）
        useCORS: true, // 支持图片跨域
        scale: 1 // 设置放大的倍数
      }).then((canvas) => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        const url = canvas.toDataURL('image/png') // toDataURL: 图片格式转成 base64
        this.imgUrl = url
        this.$refs.shareimg.src = url
        // 将图片下载到本地
        // const a = document.createElement('a') // 生成一个a元素
        // const event = new MouseEvent('click') // 创建一个单击事件
        // a.download = 'tttt1111' // 设置图片名称没有设置则为默认
        // a.href = this.imgUrl // 将生成的URL设置为a.href属性
        // a.dispatchEvent(event) // 触发a的单击事件
      })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  .tips {
    margin-top: 10px;
    padding: 20px;
    text-align: left;
    border-radius: 5px;
    color: #fff;
    .title {
      font-size: 14px;
      font-weight: 800;
      line-height: 30px;
    }
    .item {
      line-height: 20px;
    }
  }
  .button {
    margin-top: 10px;
    .btn {
      margin-left: 5px;
    }
  }
  .qrimg {
    text-align: center;
    padding: 10px 0px 20px 0px;
    .title {
      font-size: 16px;
      font-weight: 800;
      line-height: 20px;
    }
    .qrtips {
      font-size: 16px;
      font-weight: 600;
    }
    .img {
      margin: 5px auto;
      width: 200px;
      height: 200px;
    }
  }
}
</style>
